<script setup lang="ts">

import Hamburguer from "~/components/Hamburguer.vue";

useHead({
  title: 'SES Tracker',
  meta: [{
    name: 'description',
    content: 'A web application for managing email statuses, assigning applications to clients, creating email templates, and managing customer information. Streamline your workflow with our comprehensive email and client management tools.'
  }]
})

const mobileMenu = ref<boolean>(false)
const links = [
  {
    label: 'Overview',
    to: '/overview'
  },
  {
    label: 'Apps',
    to: '/apps'
  },
  {
    label: 'Clients',
    to: '/clients'
  },
  {
    label: 'Templates',
    to: '/templates'
  }
]

const items = [
  [
    {
      label: 'Profile',
      avatar: {
        src: 'https://avatars.githubusercontent.com/u/61524809?v=4'
      }
    }
  ],
  [
    {
      slot: 'colorMode',
      click: () => {
        isDark.value = !isDark.value
      }
    },
  ],
  [
    {
      label: 'Sign out',
      icon: 'i-heroicons-solid-arrow-left-on-rectangle',
      to: '/login'
    }
  ]
]

const user = useState('user-data')

const colorMode = useColorMode()

const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})

</script>

<template>
  <div class="pt-6 px-8 lg:px-[150px] relative">
    <header class="max-w-[1212px] mx-auto flex justify-between items-center">
        <img v-if="colorMode.value === 'dark'" class="w-48" src="~/assets/svg/logo-dark.svg" alt="SES Tracker"/>
        <img v-else class="w-48" src="~/assets/svg/logo.svg" alt="SES Tracker"/>
        <!-- Desktop menu-->
        <div class="hidden md:inline-block">
          <UHorizontalNavigation :links="links"/>
        </div>
        <div class="w-48 hidden  md:flex justify-end">
          <UDropdown :items="items">
            <UButton color="gray" label="My account" trailing-icon="i-heroicons-chevron-down-20-solid"
                     class="dark:ring-primary-500 text-primary-900 dark:text-primary-500 ">
              <template #trailing>
                <UIcon name="i-heroicons-chevron-down-20-solid" class="w-5 h-5 text-primary-900 dark:text-primary-500"/>
              </template>
            </UButton>
            <template #colorMode="{ item }">
              <template v-if="isDark">
                <UIcon name="i-heroicons-sun" class="h-5 w-5 text-gray-400 dark:text-gray-500"/>
                <span class="truncate">Light mode</span>
              </template>
              <template v-else>
                <UIcon name="i-heroicons-moon" class="h-5 w-5 text-gray-400 dark:text-gray-500"/>
                <span class="truncate">Dark mode</span>
              </template>
            </template>
          </UDropdown>
        </div>
      <!-- end Desktop menu-->
      <!-- Mobile menu-->
      <Hamburguer class="block md:hidden" :open="mobileMenu" @click="mobileMenu = !mobileMenu"/>

    </header>
    <div class="pt-10 grid justify-items-center">
      <slot></slot>
    </div>
  </div>

</template>

<style scoped>

</style>